import React from "react";
const False = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#d81e06",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    p-id="11296"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M960 140.8l-76.8-64-364.8 320L192 64 64 192l347.733333 298.666667-341.333333 296.533333 128 128 324.266667-328.533333 366.933333 315.733333 64-64-341.333333-345.6L960 140.8z"
      p-id="11297"
      fill={color}
    ></path>
  </svg>
);


export default False;