import React, { Component } from "react";
import CloseIcon from "../icon/CloseIcon";
import Loading from "./common/Loading";
import Submit from "../icon/SubmitIcon";
import "../style/addSite.scss";
import Select from "react-select";
import * as GetUserListAPI from "../apis/GetUserListAPI";
import * as SubmitSiteAPI from "../apis/SubmitSiteAPI";
import * as GetSiteAPI from "../apis/GetSiteAPI";
import * as AddConfigSite from "../apis/AddConfigSite";
import queryString from "query-string";

class AddSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      userList: [],
      user: { value: "", label: "Please choose user..." },
      id: 0,
      category: "",
      url: "",
      isClick: true,
      status: 1,
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.back = this.back.bind(this);
    this.select = this.select.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.clickToMove = this.clickToMove.bind(this);
  }

  componentDidMount() {
    let userList = [];
    let user = {};
    let { location } = this.props;
    const parsed = queryString.parse(location.search);
    let userid = parsed.userid;
    let id = parsed.id;
    this.setState({ userid });

    GetUserListAPI.getUserListNoPage(userid).then((res) => {
      var data = res.data.data;
      console.log(data);
      if (data) {
        userList = data.list.map((item) => {
          return (item = {
            value: item.id,
            label: item.xingming,
          });
        });
        this.setState({ userList: userList }, () => {
          if (userid) {
            this.toggleShow();
            GetSiteAPI.getSite(parseInt(id))
              .then((res) => {
                this.toggleShow();
                var data = res.data.data;
                if (data) {
                  userList.map((item) => {
                    if (parseInt(item.value) === parseInt(data.userid)) {
                      user = item;
                      return;
                    }
                  });
                  this.setState({
                    id: parseInt(id),
                    url: data.url,
                    category: data.category,
                    user: user,
                    status: data.status,
                    isClick: data.status > 0 ? true : false,
                  });
                }
              })
              .catch((err) => {
                this.toggleShow();
              });
          }
        });
      }
    });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  select(e) {
    console.log(e);
    this.setState({ user: e });
  }

  inputChange(e, type) {
    switch (type) {
      case "url":
        this.setState({ url: e.target.value });
        break;
      case "category":
        this.setState({ category: e.target.value });
        break;
      default:
        break;
    }
  }

  clickToMove() {
    let { isClick, status } = this.state;
    status = isClick ? 0 : 1
    this.setState({ isClick: !this.state.isClick, status });
  }

  submit() {
    const { id, user, category, url, status } = this.state;
    this.toggleShow();
    SubmitSiteAPI.addSite(id, url, category, user.value, status)
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        if (code === 1) {
          if (id !== 0) {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "修改成功",
              })
            );
            this.back();
          } else {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "新增成功",
              })
            );
            this.back();
          }
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  render() {
    const { userList, user, url, category, isClick } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="addSite-page">
          <CloseIcon
            size={30}
            className="icon close"
            color="#285db1"
            onClick={this.back}
          />
          <div className="row">
            <div className="title">user :</div>
            <Select
              options={userList}
              value={user || ""}
              onChange={this.select}
              className="control"
              placeholder="Please select user..."
            />
          </div>
          <div className="row">
            <div className="title">site url:</div>
            <input
              type="text"
              value={url || ""}
              onChange={(e) => this.inputChange(e, "url")}
            />
          </div>

          <div className="row">
            <div className="title">category:</div>
            <input
              type="text"
              value={category || ""}
              onChange={(e) => this.inputChange(e, "category")}
            />
          </div>
          <div className="row">
            <div className="statuBar">
              <p className="btn-p" onClick={this.clickToMove}>
                ON
              </p>
              <p className="btn-p" onClick={this.clickToMove}>
                OFF
              </p>
              <div className={`btn ${isClick ? "move" : ""}`}></div>
            </div>
          </div>

          <div className="footer">
            <Submit
              size={40}
              color="#285db1"
              className="icon"
              onClick={this.submit}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddSite;
