import React from "react";

const PaySuccess = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#FF5C51",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="76308"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512.001 107.277c150.311 0 283.357 63.054 364.763 159.84 2.897 3.448-114.618 57.151-254.388 120.487-73.142 33.14-151.989 72.4-218.584 91.971-28.039 9.173-116.355-109.077-136.586-74.457-12.862 8.715 9.008 47.298 29.415 91.003 26.635 57.031 56.087 121.116 56.74 122.031 3.342 3.055 13.326 9.899 32.365 5.161 90.349-45.464 521.376-314.965 525.718-307.748 31.438 52.284 49.17 111.551 49.17 174.351 0 211.331-200.851 382.648-448.614 382.648-53.834 0-105.458-8.088-153.28-22.921-26.893-9.12-123.981 75.779-144.047 66.347-6.9-3.241-10.625-9.407-8.407-16.54 35.216-122.873 33.844-101.422-6.19-134.521-84.29-69.564-136.692-167.067-136.692-275.013 0-211.324 200.852-382.639 448.617-382.639z"
      fill={color}
      p-id="76309"
    ></path>
  </svg>
);

export default PaySuccess;
