import React, { Component } from "react";
import "../style/entrance.scss";
import { setAPIAuthorizationToken } from "../apis/API";
import LogOutIcon from "../icon/LogOutIcon";
import HamburgerIcon from "../icon/HamburgerIcon";
import AllowRight from "../icon/AllowRight";
import cookie from "react-cookies";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  Redirect,
} from "react-router-dom";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import LeftBar from "./common/LeftBar";
import Home from "./Home";
import User from "./User";
import Site from "./Site";
import Cost from "./Cost";
import Order from "./Order";
import AddUser from "./AddUser";
import AddSite from "./AddSite";
import AddCost from "./AddCost";
import AddConfig from "./AddConfig";

class Entrance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reduce: true,
      username: "",
    };
    this.routeLogin = this.routeLogin.bind(this);
    this.clickReduce = this.clickReduce.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    if (param) {
      this.setState({ username: param.user.username });
      setAPIAuthorizationToken(param.token);
    } else {
      const { history } = this.props;
      history.push("/Login");
    }
  }

  clickReduce() {
    this.setState({ reduce: !this.state.reduce });
  }
  searchInput(e) {
    this.setState({ searchValue: e.target.value });
  }
  routeLogin() {
    const { history } = this.props;
    cookie.remove("token");
    history.push("/Login");
    window.dispatchEvent(
      new CustomEvent("showAlert", {
        detail: "已成功退出登录",
      })
    );
  }

  render() {
    const { reduce, username } = this.state;
    return (
      <React.Fragment>
        <div className="body-container">
          {/* 左 */}
          <div className={`left-bar ${reduce ? "reduce-left" : ""}`}>
            <LeftBar {...this.props} />
          </div>
          {/* 右 */}
          <div className={`right-bar ${reduce ? "reduce-right" : ""}`}>
            <header>
              <div className="head-bar">
                <div className="nav-header">
                  <div className="header-left">
                    {reduce ? (
                      <AllowRight
                        size={30}
                        color="#1362FC"
                        onClick={this.clickReduce}
                      />
                    ) : (
                      <HamburgerIcon
                        size={30}
                        color="#1362FC"
                        onClick={this.clickReduce}
                      />
                    )}
                  </div>
                  <div className="header-right">
                    <div className="profile">
                      <div className="left">
                        <div className="user-text">
                          <p>Hi,</p>
                          <h6>{username}</h6>
                        </div>
                      </div>
                      {/* logout */}
                      <div className="logout" onClick={this.routeLogin}>
                        <LogOutIcon
                          size={28}
                          color="#1362FC"
                          className="logout-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="page-contain">
              <Switch>
                <Route path="/AddConfig" component={AddConfig}></Route>
                <Route path="/AddCost" component={AddCost}></Route>
                <Route path="/AddSite" component={AddSite}></Route>
                <Route path="/AddUser" component={AddUser}></Route>
                <Route path="/Order" component={Order}></Route>
                <Route path="/Cost" component={Cost}></Route>
                <Route path="/Site" component={Site}></Route>
                <Route path="/User" component={User}></Route>
                <Route path="/Home" component={Home}></Route>
                <Redirect exact to="/Home" />
              </Switch>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Entrance;
