import React from "react";

const AdIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#333",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="25650"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M758.728128 960.481656H207.412982c-11.981185 0-21.373471 13.426152-21.373471 30.585136s9.392286 30.585136 21.373471 30.585137h551.315146c11.981185 0 21.373471-13.426152 21.373471-30.585137 0-17.03857-9.332079-30.464722-21.373471-30.585136zM972.824083 38.953904c-19.567262-19.567262-45.516463-30.344309-73.091251-30.344309H123.84572C66.829727 8.609595 20.470367 55.029163 20.470367 112.105362v678.111007c0 57.076199 46.419567 103.495767 103.43556 103.495766h775.766698c57.015992 0 103.43556-46.419567 103.435559-103.495766V112.105362c0-27.574788-10.777046-53.584196-30.284101-73.151458z m-30.946378 73.151458v678.111007c0 23.300094-18.965193 42.265287-42.265287 42.265287H123.84572c-11.258702 0-21.855127-4.395108-29.802446-12.462841-7.947319-7.947319-12.342427-18.543744-12.342427-29.802446V112.105362c0-23.300094 18.965193-42.265287 42.265287-42.265287h775.766698c11.258702 0 21.855127 4.395108 29.802446 12.462841 7.947319 7.947319 12.342427 18.543744 12.342427 29.802446z"
      fill={color}
      p-id="25651"
    ></path>
    <path
      d="M659.567262 299.047977H546.67921V603.273754h109.75729c47.262465 0 84.289746-14.26905 110.118533-42.506115 24.56444-26.852305 36.967074-63.759172 36.967074-109.697084-0.120414-46.660395-11.680151-83.446849-34.438382-109.396049-24.684854-28.297272-61.531515-42.626529-109.516463-42.626529zM744.760113 451.130762c0 32.150517-7.52587 56.714958-22.336783 72.970837-15.533396 16.25588-39.616181 24.504233-71.646284 24.504234h-45.396049V353.655691h48.888053c32.030103 0 55.510818 8.007526 69.719661 23.721543 13.787394 15.533396 20.771402 40.338664 20.771402 73.753528zM336.677328 298.867357l-119.691439 304.285983h63.337723l29.441205-77.606774h108.492944l29.441204 77.606774h63.819379L392.007526 298.867357h-55.330198z m61.290687 172.793979H330.29539L364.252117 382.795861l33.715898 88.865475z"
      fill={color}
      p-id="25652"
    ></path>
  </svg>
);


export default AdIcon;