import React from "react";

const NumberIcon = ({
  viewBox = "0 0 24 24",
  color = "#333",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1240 1024"
    p-id="30774"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M345.548456 1023.97251H116.557315A116.639785 116.639785 0 0 1 0 907.442685V116.529826A116.612295 116.612295 0 0 1 116.557315 0.02749h55.254766a31.640913 31.640913 0 1 1 0 63.226846H116.557315A53.33047 53.33047 0 0 0 63.226846 116.474846v790.912859a53.35796 53.35796 0 0 0 53.330469 53.248h228.991141a31.668403 31.668403 0 0 1 0 63.336805z m647.662819-640.515436a31.723383 31.723383 0 0 1-31.613423-31.640913V116.447356A53.33047 53.33047 0 0 0 908.267383 63.226846H167.688591a31.640913 31.640913 0 1 1 0-63.226846h740.578792a116.859705 116.859705 0 0 1 116.832214 116.502336V351.871141a31.778362 31.778362 0 0 1-31.888322 31.668403zM659.48349 970.504591h-197.102819v-216.593182h197.102819v216.703141z m-133.601074-63.226846h70.099329v-90.13949h-70.099329v90.13949z m423.894765 63.226846h-196.827919V507.051812h196.827919v463.535248z m-133.601074-63.226846h70.374229V570.333638h-70.374229v336.971597z m424.169665 63.226846h-196.82792V640.460456h196.82792v330.126604z m-133.601074-63.226846h70.374228v-203.425503h-70.374228v203.425503zM378.811275 383.512054H165.214497a31.668403 31.668403 0 0 1 0-63.226846h213.596778a31.668403 31.668403 0 1 1 0 63.254336z m73.67302 213.459329H165.214497a31.640913 31.640913 0 0 1 0-63.226846h287.269798a31.640913 31.640913 0 0 1 0 63.254335z"
      fill={color}
      p-id="30775"
    ></path>
  </svg>
);

export default NumberIcon;
