import React from "react";

const AddCost = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#333",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="59922"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M516.403 813.64c-20.478 0-36.605-15.051-36.605-33.635s16.383-33.635 36.605-33.635h416.624a36.81 36.81 0 0 1 37.116 33.226 35.836 35.836 0 0 1-36.144 34.044H516.403z"
      fill={color}
      p-id="59923"
    ></path>
    <path
      d="M696.558 988.061v-417.29a33.789 33.789 0 1 1 67.27-0.46v416.573c0.256 20.222-14.642 36.86-33.175 37.116h-0.46a35.12 35.12 0 0 1-33.635-35.939z"
      fill={color}
      p-id="59924"
    ></path>
    <path
      d="M266.879 1019.136A212.817 212.817 0 0 1 54.062 806.32V212.87A213.278 213.278 0 0 1 266.879 0h418.21a213.278 213.278 0 0 1 212.818 212.766v266.213a36.604 36.604 0 0 1-73.209 0V212.766A140.07 140.07 0 0 0 685.09 73.209H266.879a139.608 139.608 0 0 0-139.609 139.66v593.45A139.608 139.608 0 0 0 266.88 945.928H449.08a36.604 36.604 0 1 1 0 73.208z"
      fill={color}
      p-id="59925"
    ></path>
    <path
      d="M698.298 374.44H237.544a36.604 36.604 0 0 1 0-73.21h460.754a36.604 36.604 0 1 1 0 73.21zM467.921 546.197H237.544a36.604 36.604 0 0 1 0-73.208h230.377a36.604 36.604 0 1 1 0 73.208z"
      fill={color}
      p-id="59926"
    ></path>
  </svg>
);

export default AddCost;
