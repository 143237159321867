import React from "react";

const AddSite = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#707070",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="53113"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512 128a384 384 0 0 1 382.72 416l-64.832 0.064a323.328 323.328 0 0 0-18.88-144.832v1.408l-155.136-0.064a1074.176 1074.176 0 0 1 5.12 143.488H583.872a1061.76 1061.76 0 0 0-5.44-143.616H445.632a1037.12 1037.12 0 0 0 0 223.104L544 623.488v70.208H456.192l1.6 8.448c13.76 66.56 33.28 110.656 50.752 115.84L512 818.56c10.304 0 21.568-14.464 32-39.808v115.904A384 384 0 1 1 512 128z m-133.632 565.76H249.216a320.192 320.192 0 0 0 168 123.456c-16.512-33.024-29.568-75.136-38.848-123.456zM212.992 399.232A318.784 318.784 0 0 0 192.512 512c0 39.68 7.232 77.696 20.48 112.768V623.36l155.136 0.064A1074.176 1074.176 0 0 1 362.496 512c0-38.72 1.92-76.16 5.632-111.424H212.992v-1.344zM512 205.44c-17.152 0-36.864 39.936-51.392 103.36l-2.816 13.056-1.6 8.384h111.552l-1.536-8.384c-13.76-66.56-33.28-110.656-50.752-115.84L512 205.44z m-94.784 1.28l-11.2 3.776a320.384 320.384 0 0 0-156.8 119.68h129.152c9.28-48.256 22.4-90.368 38.848-123.456z m189.568 0.064l2.752 5.632c15.168 32.128 27.328 72.192 36.096 117.76h129.152a320.192 320.192 0 0 0-168-123.392z"
      fill={color}
      p-id="53114"
    ></path>
    <path
      d="M790.848 601.6v105.088H896v84.16h-105.152V896h-84.096l-0.064-105.152H601.6v-84.096l105.088-0.064V601.6h84.16z"
      fill={color}
      p-id="53115"
    ></path>
  </svg>
);

export default AddSite;
