import React from "react";

const PersentIocn = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#333",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="26684"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M972.8 0H51.2a51.2 51.2 0 0 0-51.2 51.2v716.8a51.2 51.2 0 0 0 51.2 51.2h921.6a51.2 51.2 0 0 0 51.2-51.2V51.2a51.2 51.2 0 0 0-51.2-51.2z m-51.2 716.8H102.4V102.4h819.2zM870.4 921.6H153.6a51.2 51.2 0 0 0 0 102.4h716.8a51.2 51.2 0 0 0 0-102.4z"
      fill={color}
      p-id="26685"
    ></path>
    <path
      d="M557.568 485.376c0 76.288 40.96 121.856 96.256 121.856s96.256-45.568 96.256-121.856-40.96-120.832-96.256-120.832-96.256 45.056-96.256 120.832z m96.256-70.144c17.92 0 33.28 16.384 33.28 70.144s-15.36 71.168-33.28 71.168-33.28-17.92-33.28-71.168 15.36-70.144 33.28-70.144zM435.712 607.232l205.312-395.264H588.8l-205.312 395.264h52.224zM370.176 454.144c55.808 0 96.768-45.568 96.768-121.856s-40.96-120.32-96.768-120.32S273.92 256 273.92 332.288s40.448 121.856 96.256 121.856z m0-192c17.92 0 33.28 16.896 33.28 70.144s-15.36 71.68-33.28 71.68-33.28-18.432-33.28-71.68 15.36-70.144 33.28-70.144z"
      fill={color}
      p-id="26686"
    ></path>
  </svg>
);

export default PersentIocn;
