
import cookie from "react-cookies";
import * as GetOrderListAPI from "../apis/GetOrderListAPI";

export const getOrderList = (starttime, endtime) => {
    const param = cookie.load("token");
    let userid = 0;
    if (param) {
        if (param.user.type !== 2) {
            userid = param.user.id;
        }
    }
    GetOrderListAPI.getOrderList(1, 5000, userid, null, null, null, null, starttime, endtime)
        .then((res) => {
            const data = res.data.data.list;
            const formatData = formatOrderListByAd(data);
            console.log(formatData);
        });
}

const formatOrderListByAd = (orderList) => {
    let formatData = {};
    for (let i = 0; i < orderList.length; i++) {
        const order = orderList[i];
        const { firstName, email, data, states } = order;
        const { ad, phone } = data;
        if (!firstName || firstName.trim() === '' || !email || email.trim() === '' || !phone || phone.trim() === '') {
            continue;
        }
        if (!formatData[ad]) {
            formatData[ad] = {
                ad,
                data: [],
                paid: [],
                totalNum: 0,
                paidNum: 0
            };
        }

        const orderArrInFormatData = formatData[ad].data;
        const sameOrderList = orderArrInFormatData.filter(item => {
            return (item.firstName === firstName || item.phone === phone || item.email === email) && item.states === states;
        });
        if (sameOrderList.length === 0) {
            // 没有重复订单
            const order = {
                firstName,
                email,
                phone,
                states
            }
            formatData[ad].data.push(order);
            if (states !== 0) {
                formatData[ad].paid.push(order)
            }
        }
    }

    let total = 0;
    let paid = 0;
    for (let key in formatData) {
        let orderData = formatData[key];
        orderData.data = unique(orderData.data);
        orderData.paid = unique(orderData.paid);
        orderData.totalNum = orderData.data.length;
        orderData.paidNum = orderData.paid.length;
        total += orderData.totalNum;
        paid += orderData.paidNum;
    }

    formatData['zzz--statistics'] = {
        total,
        paid
    }

    return formatData;
}

const unique = (arr) => {
    for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
            if ((arr[i].firstName == arr[j].firstName) || (arr[i].email == arr[j].email) || (arr[i].phone == arr[j].phone)) {         //第一个等同于第二个，splice方法删除第二个
                arr.splice(j, 1);
                j--;
            }
        }
    }
    return arr;
}

window.getOrderList = getOrderList;
