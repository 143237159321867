import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const login = (username, password, code) => {
  return api.post(`${ApiEndpointConstants.LOGIN}`, {
    username,
    password,
    code,
  });
};
