import React, { Component } from "react";
import CloseIcon from "../icon/CloseIcon";
import "../style/addUser.scss";
import Loading from "./common/Loading";
import Submit from "../icon/SubmitIcon";
import * as SubmitUserAPI from "../apis/SubmitUserAPI";
import * as GetUserAPI from "../apis/GetUserAPI";

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      username: "",
      password: "",
      nickname: "",
      xingming: "",
      id: 0,
    };
    this.inputChange = this.inputChange.bind(this);
    this.submit = this.submit.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.back = this.back.bind(this);
  }

  componentDidMount() {
    let { location } = this.props;
    if (location.pathname.indexOf("/AddUser/") >= 0) {
      let id = location.pathname.replace("/AddUser/", "");
      this.toggleShow();
      GetUserAPI.getUser(id).then((res) => {
        this.toggleShow();
        console.log(res);
        var data = res.data.data;
        if (data) {
          this.setState({
            id: parseInt(id),
            username: data.username,
            password: data.password,
            nickname: data.nickname,
            xingming: data.xingming,
          });
        }
      });
    }
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  inputChange(e, type) {
    switch (type) {
      case "username":
        this.setState({ username: e.target.value });
        break;
      case "password":
        this.setState({ password: e.target.value });
        break;
      case "nickname":
        this.setState({ nickname: e.target.value });
        break;
      case "xingming":
        this.setState({ xingming: e.target.value });
        break;
      default:
        break;
    }
  }

  submit() {
    const { id, username, password, nickname, xingming } = this.state;
    this.toggleShow();
    SubmitUserAPI.submitUser(id, username, password, nickname, xingming).then(
      (res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        console.log(msg);
        if (code === 1) {
          if (id !== 0) {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "修改用户成功",
              })
            );
            this.back();
          } else {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "新增用户成功",
              })
            );
            this.back();
          }
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      }
    );
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  render() {
    const { username, password, nickname, xingming } = this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="addUser-page">
          <CloseIcon
            size={30}
            className="icon close"
            color="#285db1"
            onClick={this.back}
          />
          <div className="row">
            <div className="title">账号 :</div>
            <input
              type="text"
              value={username || ""}
              onChange={(e) => this.inputChange(e, "username")}
            />
          </div>
          <div className="row">
            <div className="title">密码 :</div>
            <input
              type="text"
              value={password || ""}
              onChange={(e) => this.inputChange(e, "password")}
            />
          </div>
          <div className="row">
            <div className="title">telegram :</div>
            <input
              type="text"
              value={nickname || ""}
              onChange={(e) => this.inputChange(e, "nickname")}
            />
          </div>
          <div className="row">
            <div className="title">姓名 :</div>
            <input
              type="text"
              value={xingming || ""}
              onChange={(e) => this.inputChange(e, "xingming")}
            />
          </div>

          <div className="footer">
            <Submit
              size={40}
              color="#285db1"
              className="icon"
              onClick={this.submit}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddUser;
