import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "../apis/API";

export const submitUser = (id, username, password, nickname, xingming) => {
  return api.post(`${ApiEndpointConstants.ADDUSER}`, {
    id,
    username,
    password,
    nickname,
    xingming,
  });
};
