import React from "react";
const OrderIcon = ({
  color = "#000000",
  size = 24,
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="21949"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M537.429 305.526c53.437 0 96.754-44.64 96.754-99.73 0-30.991-13.709-58.678-35.23-76.978 1.841 6.278 3.14 12.808 3.14 19.712 0 37.644-29.596 68.146-66.104 68.146-36.517 0-66.115-30.502-66.115-68.146 0-5.515 0.8-10.802 2.01-15.929-19.132 18.219-31.203 44.22-31.203 73.194C440.682 260.886 483.999 305.526 537.429 305.526zM534.349 178.382c35.607 0 64.472-29.749 64.472-66.46 0-36.699-28.865-66.448-64.472-66.448-35.608 0-64.474 29.749-64.474 66.448C469.875 148.633 498.74 178.382 534.349 178.382zM78.809 774.406l155.81 62.177c-12.567-25.91-62.832-417.07-62.832-417.07L78.809 774.406zM712.094 979.064l10.061-152.839-349.316 36.268L712.094 979.064zM817.639 137.149l-169.355 25.76c10.194 18.833 16.055 40.505 16.055 63.613 0 72.249-56.807 130.824-126.908 130.824-70.084 0-126.901-58.576-126.901-130.824 0-9.582 1.066-18.901 2.95-27.904l-209.025 31.788c7.536 202.06 98.011 603.591 98.011 603.591l650.886-113.984C908.113 673.377 817.639 137.149 817.639 137.149zM327.385 456.291l276.938-41.051 8.23 30.819-276.923 41.063L327.385 456.291zM386.848 689.955l-10.06-35.15 340.838-49.995 10.07 35.149L386.848 689.955zM366.201 588.733l-13.441-34.878 439.032-59.52 13.447 34.875L366.201 588.733z"
      fill={color}
      p-id="21950"
    ></path>
  </svg>
);

export default OrderIcon;
