import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const getPixel = (starttime, endtime, userid, ad, url) => {
  return api.post(`${ApiEndpointConstants.PIXEL}`, {
    starttime,
    endtime,
    userid,
    ad,
    url,
  });
};
