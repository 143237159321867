import React, { Component } from "react";
import CloseIcon from "../icon/CloseIcon";
import Loading from "./common/Loading";
import Submit from "../icon/SubmitIcon";
import "../style/addCost.scss";
import Select from "react-select";
import * as GetSiteListAPI from "../apis/GetSiteListAPI";
import * as UploadAPI from "../apis/UploadAPI";
import { BASE_URL } from "../constants/ApiEndpointConstants";
import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import axios from "axios";
import moment from "moment";
import { DatePicker, Space, Button } from "antd";
import dayjs from "dayjs";
import cookie from "react-cookies";
import * as GetCostAPI from "../apis/GetCostAPI";
import * as SubmitCostAPI from "../apis/SubmitCostAPI";
import queryString from "query-string";

class AddCost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingShow: false,
      siteList: [],
      site: { value: null, label: "Please choose site..." },
      picture: "",
      consumptiontime: "",
      userid: null,
      payexpenses: "",
      category: "",
      date: null,
      id: 0,
    };
    this.back = this.back.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.select = this.select.bind(this);
    this.UpdateImg = this.UpdateImg.bind(this);
    this.onChange = this.onChange.bind(this);
    this.setToday = this.setToday.bind(this);
    this.submit = this.submit.bind(this);
    this.changeInput = this.changeInput.bind(this);
  }

  componentDidMount() {
    let { location } = this.props;
    let site = {};
    let siteList = [];
    const parsed = queryString.parse(location.search);
    let userid = parsed.userid;
    this.setState({ userid });
    let id = parsed.id;
    GetSiteListAPI.getSiteListNoPage(parseInt(userid)).then((res) => {
      console.log(res);
      var data = res.data.data;
      if (data) {
        siteList = data.list.map((item) => {
          return (item = {
            value: item.id,
            label: item.url,
          });
        });
        this.setState({ siteList: siteList }, () => {
          if (id) {
            this.toggleShow();
            GetCostAPI.getCost(id)
              .then((res) => {
                this.toggleShow();
                var data = res.data.data;
                if (data) {
                  console.log(data);
                  console.log(siteList);
                  siteList.map((item) => {
                    if (item.label == data.url) {
                      site = item;
                      return;
                    }
                  });
                  this.setState({
                    site: site,
                    picture: data.picture,
                    payexpenses: data.payexpenses,
                    category: data.category,
                    consumptiontime: data.consumptiontime,
                    id: parseInt(id),
                  });
                  this.setToday(data.consumptiontime);
                }
              })
              .catch((err) => {
                this.toggleShow();
              });
          } else {
            this.setToday();
          }
        });
      }
    });
  }

  toggleShow() {
    this.setState({ loadingShow: !this.state.loadingShow });
  }

  back() {
    let { history } = this.props;
    history.goBack();
  }

  select(e) {
    console.log(e);
    this.setState({ site: e });
  }

  UpdateImg(e) {
    let { picture } = this.state;
    const _that = this;
    var formData = new FormData();
    [...e.target.files].forEach((fileInfo, index) => {
      if (index > 0) return;
      let reads = new FileReader();
      reads.readAsDataURL(fileInfo);
      formData.append("file", fileInfo);
      _that.toggleShow();
      axios({
        url: BASE_URL + ApiEndpointConstants.UPLOAD,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          console.log("连上了---------");
          _that.toggleShow();
          let url = res.data.data.url;
          console.log(url);
          picture = url;
          _that.setState({ picture });
          e.target.value = "";
          console.error(picture);
        })
        .catch((err) => {
          _that.toggleShow();
        });
    });
  }

  setToday = (time) => {
    let today = dayjs();
    if (time) {
      today = dayjs(time * 1000);
      this.setState({ date: today });
    } else {
      let consumptiontime = parseInt(today.valueOf() / 1000);
      this.setState({ date: today, consumptiontime });
    }
  };

  onChange(date, dateString) {
    this.setState({ date });
    if (date) {
      const consumptiontime = parseInt(date.valueOf() / 1000); // 获取时间戳
      console.log(consumptiontime);
      this.setState({ date, consumptiontime });
    }
  }

  changeInput(e, type) {
    switch (type) {
      case "price":
        this.setState({ payexpenses: e.target.value });
        break;
      case "category":
        this.setState({ category: e.target.value });
        break;
      default:
        break;
    }
  }

  submit() {
    let { site, picture, consumptiontime, payexpenses, category, id, userid } =
      this.state;
    this.toggleShow();
    SubmitCostAPI.submitCost(
      id,
      payexpenses,
      picture,
      site.label,
      consumptiontime,
      category,
      userid
    )
      .then((res) => {
        this.toggleShow();
        var code = res.data.code;
        var msg = res.data.msg;
        console.log(msg);
        if (code === 1) {
          if (id !== 0) {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "修改成功",
              })
            );
            this.back();
          } else {
            window.dispatchEvent(
              new CustomEvent("showAlert", {
                detail: "新增成功",
              })
            );
            this.back();
          }
          return;
        }
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      })
      .catch((err) => {
        this.toggleShow();
      });
  }

  render() {
    const { siteList, site, picture, payexpenses, category, date, userid } =
      this.state;
    return (
      <React.Fragment>
        <Loading {...this.props} loadingShow={this.state.loadingShow} />
        <div className="addCost-page">
          <CloseIcon
            size={30}
            className="icon close"
            color="#285db1"
            onClick={this.back}
          />
          <div className="row">
            <div className="title">time:</div>
            <DatePicker onChange={this.onChange} value={date} />
          </div>
          <div className="row">
            <div className="title">site:</div>
            <Select
              options={siteList}
              value={site || ""}
              onChange={this.select}
              className="control"
              placeholder="Please select site..."
            />
          </div>
          <div className="row">
            <div className="title">price:</div>
            <input
              type="text"
              className="input"
              value={payexpenses || ""}
              onChange={(e) => this.changeInput(e, "price")}
            />
          </div>
          {/* <div className="row">
            <div className="title">category:</div>
            <input
              type="text"
              className="input"
              value={category || ""}
              onChange={(e) => this.changeInput(e, "category")}
            />
          </div> */}
          {/* <div className="row">
            <div className="title">proof:</div>
            <div className="fileBox">
              <input
                type="file"
                className="file"
                onChange={(e) => this.UpdateImg(e)}
              />
              <img src={BASE_URL + picture} alt="" />
            </div>
          </div> */}

          <div className="footer">
            {/* {userid !== 1 ? (
              <Submit
                size={40}
                color="#285db1"
                className="icon"
                onClick={this.submit}
              />
            ) : (
              ""
            )} */}
            <Submit
              size={40}
              color="#285db1"
              className="icon"
              onClick={this.submit}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AddCost;
