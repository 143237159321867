import React from "react";

const True = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "#196d1f",
  ...props
}) => (
  <svg
    viewBox="0 0 1479 1024"
    p-id="3234"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M1401.287111 0L1479.111111 77.824 544.938667 1012.053333 0 467.114667l136.248889-136.248889 447.601778 291.896889L1401.287111 0z"
      fill={color}
      p-id="3235"
    ></path>
  </svg>
);

export default True;
