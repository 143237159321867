import React from "react";

const JiangBei = ({
  viewBox = "0 0 24 24",
  color = "#333",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="29876"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M657.4 81.1l11.4-69.5 11.4 69.5 69.5 11.4-69.4 11.5-11.4 69.5-11.5-69.5-69.5-11.4 69.5-11.5zM79.8 770.3L91 702.8l11.2 67.5 67.5 11.2-67.5 11.2L91 860.2l-11.2-67.5-67.5-11.2 67.5-11.2z m733.6-548l7.5-45.7 7.5 45.7 45.7 7.5-45.7 7.5-7.5 45.7-7.5-45.7-45.7-7.5 45.7-7.5zM288.7 624.7c0.4 0.8 0.8 1.6 1.2 2.2 3.1 5.7 6.4 11.2 9.9 16.6 0.4 0.5 0.8 1.2 1.2 1.8 3.6 5.5 7.4 10.9 11.4 16.2 0.1 0.1 0.1 0.3 0.4 0.4 8.3 10.8 17.5 20.9 27.7 30.1l0.5 0.5c4.9 4.5 10 8.8 15.2 12.9 0.3 0.1 0.5 0.4 0.8 0.5 21.6 16.8 46.1 29.5 72.2 37.5 0.1 0 0.1 0.1 0.3 0.1 6.6 1.9 13.2 3.6 20 5.1 7 1.4 14.2 2.6 21.3 3.2 0.1 0 0.4 0 0.5 0.1 7.3 0.8 14.4 1.2 21.7 1.2 7.3 0 14.4-0.4 21.6-1.2 0.1 0 0.4 0 0.5-0.1-86.8-34.4-165.1-84.3-229.9-134.2 1 2.5 2.2 4.9 3.5 7.1z m328.9 282c-3.1-11.4-13.5-19.4-25.3-19.4h-56v-95.5c-14.2 2.3-28.7 3.6-43.5 3.6s-29.4-1.3-43.5-3.6V887c56.8 36.4 120.4 69.4 188.5 92-0.3-1.3-0.8-2.6-1.3-3.8l-18.9-68.5z m-269.4 71.7c-2.2 7.9-0.5 16.5 4.4 23s12.7 10.4 20.9 10.4h69.9c-31.4-17.4-61.3-36.4-89.1-55.9l-6.1 22.5z m580.1-67.6l11.2-67.5 11.2 67.5 67.5 11.2-67.5 11.2-11.2 67.5-11.2-67.5-67.5-11.2 67.5-11.2zM34.2 645l4.9-29.9L44 645l29.9 4.9-29.9 4.9-4.9 29.9-4.9-29.9-29.9-4.9 29.9-4.9z"
      fill={color}
      p-id="29877"
    ></path>
    <path
      d="M449.2 887.5h-55.9a26.2 26.2 0 0 0-25.3 19.4L354.4 956c27.9 19.5 57.7 38.4 89.1 55.9h168.7c14.5 0 26.4-11.8 26.4-26.4 0-2.2-0.3-4.4-0.8-6.5-68.1-22.6-131.6-55.5-188.5-92l-0.1 0.5z"
      fill={color}
      p-id="29878"
    ></path>
    <path
      d="M235.9 380.4c3.6-0.9 7.4-1.4 11.2-1.4 3.4 0 6.8 0.5 10.1 1.2v71.6c41.2 37.9 93.3 81.2 153.5 120.9l23.1-76.1-70-52.7c-2.7-2.1-3.9-5.6-2.9-8.8s4-5.5 7.3-5.5l88.1-1.8L471 386c-71.2-53.9-126.8-108.6-160.4-144.3h-28.4c-13.8 0-24.8 11.2-24.8 24.9v70.7c-3.4-0.4-6.8-0.5-10.1-0.5-7.4 0-14.9 1-22.1 2.9-19.1 5.1-35.2 16.5-47.5 32.2 9.6 10.7 19.5 21 29.5 31.3 7.2-11.7 16.9-19.7 28.7-22.8z"
      fill={color}
      p-id="29879"
    ></path>
    <path
      d="M420.6 588.7c-2.7 1.9-6.5 1.9-9.2-0.1-2.7-1.9-3.9-5.6-2.9-8.7l2.2-7.3c-60.1-39.9-112.4-83-153.5-120.9v46c0 7.1 0.4 14.3 0.9 21.3 0.1 0.9 0.1 1.8 0.1 2.9 0.5 6.6 1.4 13.2 2.5 19.7 0.1 1.2 0.4 2.2 0.5 3.5 1.2 6.5 2.5 12.9 4 19.1 0.1 0.9 0.5 1.9 0.8 2.9 0.3 1.2 0.5 2.2 0.9 3.2-29.9-4.9-59-36.8-70-78.2-9-33.8-4.2-67.4 10-89.1-10-10.3-19.9-20.7-29.5-31.3-25.1 31.8-34.6 81.2-21.3 131.2 0.1 0.6 0.4 1.2 0.5 1.8 30.9 31 72.3 69.5 122.1 108.5 1.3-0.1 2.5-0.3 3.8-0.3 0.8 1.7 1.6 3.4 2.5 5.1 64.8 49.9 143.1 99.8 229.9 134.2 7.1-0.8 14.2-1.8 21.2-3.2h0.3c6.8-1.4 13.4-3.1 19.9-5.1 0.1 0 0.1-0.1 0.3-0.1 26.2-7.9 50.5-20.9 72.2-37.5 0.3-0.1 0.5-0.4 0.8-0.5 5.3-4 10.4-8.4 15.3-12.9 0.1-0.1 0.4-0.3 0.5-0.5 2.5-2.3 4.9-4.5 7.3-7-81.7-21.4-157.8-59-224.8-101.3l-7.3 4.6z"
      fill={color}
      p-id="29880"
    ></path>
    <path
      d="M718.6 570.1c0.3-1 0.5-2.2 0.9-3.2 0.3-0.9 0.5-1.9 0.8-2.9 1.6-6.4 3-12.6 4-19.2 0.1-1.2 0.4-2.2 0.5-3.5 0.8-4.8 1.4-9.7 1.9-14.7-47.3-16.1-92.4-37.3-134.4-60.9l-40.8 30.8 25.5 83.4c1 3.2-0.1 6.8-2.9 8.7-1.4 1-3 1.6-4.5 1.6-1.6 0-3.2-0.5-4.5-1.4l-72.2-49.9-64.9 44.8c66.9 42.5 143 79.9 224.8 101.3 7.3-7.3 14.2-15.1 20.5-23.3 0.1-0.1 0.1-0.3 0.3-0.4 4-5.2 7.9-10.7 11.4-16.2 0.4-0.5 0.8-1.2 1.2-1.8 3.5-5.5 6.8-10.9 9.9-16.6 0.4-0.6 0.8-1.4 1.2-2.2 2.1-3.9 4.2-8.1 6-12.1 2.6 0.3 5.2 0.5 7.7 0.5 37.7 0 74.9-24.5 98.8-63.8-14.8-2.9-29.5-6.4-44-10.4-13.4 17.1-30.2 28.7-47.2 31.4z"
      fill={color}
      p-id="29881"
    ></path>
    <path
      d="M529.9 427.6l-1.2-0.8 0.3 0.8h0.9z"
      fill={color}
      p-id="29882"
    ></path>
    <path
      d="M835.2 448.7c-14.8-3.8-29.5-8.1-43.9-12.9 3.1 17.1 2.6 36.6-2.6 56.2-4.8 17.8-12.9 33.9-23 46.9 14.5 4 29.2 7.5 44 10.4 8.8-14.5 15.5-30.1 19.9-46.6 4.7-18.4 6.6-36.7 5.6-54z"
      fill={color}
      p-id="29883"
    ></path>
    <path
      d="M156.7 504.5c17.8 64.3 67.5 108.6 118.1 108.6 1.3 0 2.6-0.1 4-0.1-49.7-38.8-91.2-77.4-122.1-108.5zM728.2 380c3.4-0.6 6.8-1.2 10.1-1.2 3.8 0 7.5 0.5 11.2 1.4 21.6 5.7 36.5 27.5 41.7 55.5 14.4 4.8 29.1 9.1 43.9 12.9-2.9-53.1-31-97.5-74.7-109.2-7.1-1.9-14.7-3-22.1-2.9-3.4 0-6.8 0.1-10.1 0.5v-70.7c0-13.8-11-24.9-24.8-24.9H466.6c68.1 58.8 158.2 124.7 261.7 170l-0.1-31.4z"
      fill={color}
      p-id="29884"
    ></path>
    <path
      d="M470.9 385.8l14.3-40.7c1.2-3.2 4-5.3 7.4-5.3s6.2 2.2 7.4 5.3l28.6 81.7 1.2 0.8 86.9 1.8c3.4 0.1 6.4 2.2 7.4 5.5 1.2 3.2 0.1 6.8-2.6 8.8l-29.4 22.1c42.1 23.6 87.2 44.8 134.4 60.9 0.1-1.7 0.4-3.4 0.5-5.1 0.1-0.9 0.1-1.8 0.1-2.9 0.5-7.1 0.8-14.3 0.8-21.3v-86c-103.5-45.2-193.7-111.1-261.7-170H310.6c33.7 35.8 89.3 90.5 160.3 144.4z"
      fill={color}
      p-id="29885"
    ></path>
  </svg>
);

export default JiangBei;
