import api from "./API";

export const sendEmail = (
  emailTemplateId,
  toEmailUser,
  locating,
  replace,
  subjectlocating,
  subjectreplace,
  username
) => {
  return api.post(`https://www.hsmail.vip/api/External/SingleEmailSend`, {
    emailTemplateId,
    toEmailUser,
    locating,
    replace,
    subjectlocating,
    subjectreplace,
    username,
  });
};
