import React from "react";

const AddUserIcon = ({
  viewBox = "0 0 24 24",
  color = "rgb(65, 71, 85)",
  size = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="33599"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M864 800h-64v-60.8c0-19.2-12.8-32-32-32s-32 12.8-32 32V800h-64c-19.2 0-32 12.8-32 32s12.8 32 32 32h64v67.2c0 19.2 12.8 32 32 32s32-12.8 32-32V864h64c19.2 0 32-12.8 32-32s-12.8-32-32-32z"
      fill={color}
      p-id="33600"
    ></path>
    <path
      d="M704 931.2V896h-32c-35.2 0-64-28.8-64-64s28.8-64 64-64h32v-28.8c0-25.6 12.8-44.8 32-54.4-22.4-22.4-64-38.4-86.4-48 86.4-51.2 147.2-144 147.2-249.6 0-160-128-288-288-288s-288 128-288 288c0 108.8 60.8 201.6 147.2 249.6-121.6 48-214.4 153.6-240 288-3.2 9.6 0 19.2 6.4 25.6 6.4 6.4 16 12.8 25.6 12.8h553.6c-3.2-9.6-9.6-22.4-9.6-32z"
      fill={color}
      p-id="33601"
    ></path>
  </svg>
);

export default AddUserIcon;