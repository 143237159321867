import React from "react";

const PayError = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = '"#FF5C51"',
  ...props
}) => (
  <svg
    viewBox="0 0 1134 1024"
    p-id="9859"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M1073.142857 264c-5.714286-9.142857-40-57.142857-43.428571-61.714286s-6.857143-8-11.428572-12.571428L729.142857 480l189.714286 193.142857c6.857143 6.857143 6.857143 18.285714 0 25.142857l-19.428572 20.571429c-6.857143 6.857143-17.142857 6.857143-25.142857 1.142857L665.142857 545.142857 482.285714 731.428571c-11.428571 11.428571-29.714286 11.428571-41.142857 0L388.571429 678.857143c-9.142857-9.142857-8-24 1.142857-32l197.714285-169.142857-173.714285-153.142857c-12.571429-10.285714-12.571429-29.714286-1.142857-41.142858l50.285714-50.285714c10.285714-10.285714 27.428571-10.285714 37.714286 1.142857L662.857143 411.428571l305.142857-270.857142C865.142857 53.714286 724.571429 0 568 0 254.857143 0 0 217.142857 0 484.571429c0 137.142857 66.285714 259.428571 172.571429 347.428571 50.285714 42.285714 52.571429 14.857143 8 170.285714-2.285714 9.142857 2.285714 17.142857 10.285714 20.571429 25.142857 11.428571 148.571429-96 181.714286-83.428572 60.571429 18.285714 125.714286 28.571429 194.285714 28.571429 313.142857 0 568-217.142857 568-484.571429 0-77.714286-21.714286-153.142857-61.714286-219.428571z"
      fill={color}
      p-id="9860"
    ></path>
  </svg>
);

export default PayError;
